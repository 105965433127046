<template>
  <div class="subscription-block">
    <div class="header">
      <div style="height: 20px; line-height: 20px;">
        <span class="pull-right">
          <strong>{{ used }}</strong> / {{ limit }}
        </span>
        <span class="title">Pageview limit (30 days):</span>
      </div>
      <div class="progress">
        <div class="bar" :style="{ width: this.pageViewInfo.percent }"></div>
      </div>
    </div>
    <a class="btn btn-primary" @click="handleClick">Change Plan</a>
  </div>
</template>

<script>
// import * as promoApi from '@/vai/api/promotion'
// import * as utils from '@/vai/utils'

export default {
  data () {
    return {
      pageViewInfo: {
        limit: 0,
        used: 0,
        percent: '0%'
      }
    }
  },
  computed: {
    limit () {
      if (this.pageViewInfo) {
        return this.pageViewInfo.limit
      } else {
        return ''
      }
    },
    used () {
      if (this.pageViewInfo) {
        return this.pageViewInfo.used
      } else {
        return ''
      }
    }
  },
  created () {
    // window.addEventListener('storage', function (e) {
    //   console.log('[event]', e)
    //   if (e.storageArea === sessionStorage) {
    //     const item = sessionStorage.getItem('pageViewInfo')
    //     if (item) {
    //       this.pageViewInfo = JSON.parse(item)
    //     }
    //   }
    // })
    const item = sessionStorage.getItem('pageViewInfo')
    if (item) {
      this.pageViewInfo = JSON.parse(item)
    }

    const updatePageViewInfo = (pageViewInfo) => {
      this.pageViewInfo = pageViewInfo
    }
    window.addEventListener('message', function (event) {
      if (event.data.pageViewInfo != null) {
        updatePageViewInfo(event.data.pageViewInfo)
      }
    })
  },
  methods: {
    handleClick () {
      this.$router.push({ path: '/promotion/subscription' })
    },
    getPageViewInfo (value) {
      console.log('change: ', value)
    }
  }
}
</script>
<style scoped>

.subscription-block {
  display: flex;
  float: right;
  height: 50px;
  padding: 8px 19px;
  width: 28.25%;
  min-width: 380px;
  margin-top: 6px;

  .btn {
    width: 130px;
    display: block;
    float: right;
    color: #fff;
    font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.065em;
    font-size: 8px;
    line-height: 14px;
    padding: 8px 10px 6px;
    border-radius: 3px;
    margin: 4px -4px 0 14px;
    text-shadow: -1px -1px rgba(0,0,0,0.15);
    border: 0;
    box-shadow: inset 1px 1px rgba(255,255,255,0.1), 1px 1px 3px rgba(0,0,0,0.2);
    background: #7058e4;
    background: -moz-linear-gradient(90deg, #7058e4 0%, #8970ff 100%);
    background: -webkit-linear-gradient(90deg, #7058e4 0%, #8970ff 100%);
    background: linear-gradient(0deg, #7058e4 0%, #8970ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7058e4', endcolorstr='#8970ff',GradientType=1 );
    transform: none !important;
  }
  .header {
    width: 350px;
    font-size: 11px;
    color: #7058e4;
    /* text-shadow: 1px 1px 1px rgba(0,0,0,0.2); */
    padding: 2px 0 1px;

    .title {
      font-weight: 600;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      font-size: 0.94em;
      color: #7058e4;;
    }
  }

  .progress {
    height: 5px;
    margin: 3px 0 0;
    background: #91d5ff;
    border-radius: 10px;
    /* overflow: hidden; */

    .bar {
      width: 5px;
      min-width: 5px;
      height: 100%;
      background: green;
      box-shadow: 1px 0 1px rgba(0,0,0,0.3);
      border-radius: 10px;
    }
  }
}

.pull-right {
    float: right!important;
}

</style>
