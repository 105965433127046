<template>
  <a-layout id="vai-layout" style="height:100%" :menus="menus">
    <a-layout-header class="header">
      <div class="header-logo" >
        <img src="@/assets/luckydraw-logo2.svg" />
        <h1>{{ title }}</h1>
      </div>
      <!-- <a-breadcrumb class="breadcrumb">
        <a-breadcrumb-item><a href="">Home</a></a-breadcrumb-item>
        <a-breadcrumb-item>BERI Consulting, LLC</a-breadcrumb-item>
        <a-breadcrumb-item>Chat Bot</a-breadcrumb-item>
      </a-breadcrumb> -->
      <!-- <div>
        <page-header-wrapper :title="false" style="margin-top: 8px"></page-header-wrapper>
      </div> -->
      <div v-if="false">
        <span>
          <a-icon :type="menuFold.icon" @click="clickMenuIcon" :style="menuFold.style" />
        </span>
      </div>
      <div v-if="false">
        <a-button type="link" @click="showLocation" style="color: black;">{{ promotion.name }}</a-button>
      </div>
      <a-menu
        theme="light"
        mode="horizontal"
        class="menu"
        :default-selected-keys="['1']"
        :style="{ lineHeight: '62px' }"
      >
        <!-- <a-menu-item key="1" @click="handleMenu('/promotion/dashboard')">
          <a-icon type="bank" />Promotions
        </a-menu-item>
        <a-menu-item key="2" @click="handleMenu('/promotion/subscription')">
          <a-icon type="dollar" />Subscription
        </a-menu-item>
        <a-menu-item key="3" @click="handleMenu('/promotion/setting')">
          <a-icon type="setting" />Settings
        </a-menu-item>
        <a-menu-item key="4" @click="handleMenu('/promotion/logout')">
          <a-icon type="logout" />Logout
        </a-menu-item> -->
      </a-menu>
      <right-content :top-menu="settings.layout === 'topmenu'" :is-mobile="isMobile" :theme="settings.theme" />
    </a-layout-header>
    <a-layout-content>
      <slot></slot>
    </a-layout-content>
    <!-- <a-layout-footer style="background-color: #fff;">
      <global-footer />
    </a-layout-footer> -->
  </a-layout>
</template>
<script>

import { i18nRender } from '@/locales'

import GlobalFooter from '@/components/GlobalFooter'
import RightContent from '@/components/GlobalHeader/RightContent'

import defaultSettings from '@/config/defaultSettings'
import { CONTENT_WIDTH_TYPE, SIDEBAR_TYPE, TOGGLE_MOBILE_TYPE } from '@/store/mutation-types'

// import { updateTheme } from '@ant-design-vue/pro-layout'
import { mapState } from 'vuex'

export default {
  components: {
    RightContent,
    GlobalFooter
  },
  props: {
    showMenuFold: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      // preview.pro.antdv.com only use.
      isProPreviewSite: process.env.VUE_APP_PREVIEW === 'true' && process.env.NODE_ENV !== 'development',
      // end
      isDev: process.env.NODE_ENV === 'development' || process.env.VUE_APP_PREVIEW === 'true',

      // base
      menus: [],
      // 侧栏收起状态
      collapsed: false,
      // title: defaultSettings.title,
      title: 'Lucky Draw',
      settings: {
        // 布局类型
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        // CONTENT_WIDTH_TYPE
        contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
        // 主题 'dark' | 'light'
        theme: defaultSettings.navTheme,
        // 主色调
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: defaultSettings.fixedHeader,
        fixSiderbar: defaultSettings.fixSiderbar,
        colorWeak: defaultSettings.colorWeak,

        hideHintAlert: false,
        hideCopyButton: false
      },
      // 媒体查询
      query: {},

      // 是否手机模式
      isMobile: false,
      menuFold: {
        icon: 'menu-fold',
        style: { fontSize: '20px', marginLeft: '25px', top: '6px', position: 'relative', display: 'block' }
      },
      promotion: {}
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: state => state.permission.addRouters
    }),
    promotionName () {
      if (this.promotion) {
        return this.promotion.name
      } else {
        return ''
      }
    },
    locationPath () {
      if (this.$store.state.currentLocation) {
        return this.$store.state.currentLocation.path
      } else {
        return ''
      }
    }
  },
  created () {
    const routes = this.mainMenu.find(item => item.path === '/')
    this.menus = (routes && routes.children) || []
    // 处理侧栏收起状态
    this.$watch('collapsed', () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    })
    this.$watch('isMobile', () => {
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
    })
  },
  mounted () {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }

    // first update color
    // TIPS: THEME COLOR HANDLER!! PLEASE CHECK THAT!!
    if (process.env.NODE_ENV !== 'production' || process.env.VUE_APP_PREVIEW === 'true') {
      // updateTheme(this.settings.primaryColor)
    }

    this.$nextTick(() => {
      if (this.$el.querySelector('.ant-pro-grid-content.wide')) {
        this.$el.querySelector('.ant-pro-grid-content.wide').style.maxWidth = '100%'
      }

      if (!this.showMenuFold) {
        this.menuFold.style.display = 'none'
      }
    })

    //
    const updatePromotion = (promotion) => {
      this.promotion = promotion
    }
    window.addEventListener('message', function (event) {
      if (event.data.promotion) {
        updatePromotion(event.data.promotion)
      }
    })
  },
  updated () {
    this.$nextTick(() => {
      if (this.$el.querySelector('.ant-pro-grid-content.wide')) {
        this.$el.querySelector('.ant-pro-grid-content.wide').style.maxWidth = '100%'
      }
      if (this.$el.querySelector('.ant-layout-sider')) {
        this.$el.querySelector('.ant-layout-sider').style.maxWidth = '180px'
        // this.$el.querySelector('.ant-layout-sider').style.minWidth = '180px'
        this.$el.querySelector('.ant-layout-sider').style.widthidth = '180px'
        this.$el.querySelector('.ant-layout-sider').style.flex = '0 0 180px;'
      }
    })
  },
  methods: {
    i18nRender,
    handleMenu (path) {
      this.$router.push({ path })
    },
    clickMenuIcon (e) {
      if (this.menuFold.icon === 'menu-fold') {
        this.menuFold.icon = 'menu-unfold'
      } else {
        this.menuFold.icon = 'menu-fold'
      }
      this.$emit('menuCollapse')
    },
    showLocation () {
      this.$router.push({ path: this.locationPath })
    }
  }
}
</script>

<style lang="less" scoped>

.header {
  display: inline;
  padding: 0 24px;
  background-color: #f6f8fa;
  box-shadow: 1px 1px 0 0 #e8e8e8;
  -webkit-box-shadow: 1px 1px 0 0 #e8e8e8;
  border-bottom: 1px solid #e8e8e8;

  div {
    display: inline-block;
  }

  ul {
    display: inline-block;
  }

  .breadcrumb {
    margin: 0 0 0 12px;
  }

  .menu {
    margin: 0 0 0 12px;
    background-color: #f6f8fa;
    display: none;
    display: inline-block;
  }

  .header-logo {
    img, svg {
      height: 36px;
      width: 36px;
      vertical-align: middle;
    }

    h1 {
      display: inline-block;
      // color: #fff;
      font-size: 20px;
      margin: 0 0 0 12px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      font-weight: 600;
      vertical-align: middle;
    }
  }
}

.ant-layout {
  background: #fff;
}
.ant-pro-global-footer{
  margin: 24px 0 24px 0;
}

.ant-layout-footer {
    padding: 0;
}

.ant-pro-grid-content {
    max-width: 2400px;
    margin: 0 auto;
}
</style>
