import Vue from 'vue'
import notification from 'ant-design-vue/es/notification'
import { i18nRender } from '@/locales'

import request from '@/utils/request'
import router from '@/router'
import store from '@/store'
import { loadStripe } from '@stripe/stripe-js'
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js'

export const visData = {}

export const backgroundColors = [
  '#F44336', '#FF4081', '#9C27B0', '#673AB7',
  '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688',
  '#4CAF50', '#8BC34A', '#CDDC39', /* '#FFEB3B' , */ '#FFC107',
  '#FF9800', '#FF5722', '#795548', '#9E9E9E', '#607D8B']

export const regex = {
  'phone': /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  'email': /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
}
export async function initStripe (publicKey) {
  visData.stripe = await loadStripe(publicKey)
}

export function _request (parameter) {
  return new Promise((resolve, reject) => {
    if (!parameter.data) {
      parameter.data = {}
    }
    if (!parameter.method) {
      parameter.method = 'post'
    }

    parameter.withCredentials = true
    request(parameter).then(response => {
      if (response.status && response.status.code && response.status.code !== '0000') {
        // user session not exist
        if (response.status.code === '1000') {
            // reject(response)
            store.dispatch('vaiStore/user/Logout').then(() => {
          }).catch(error => {
            console.log(error)
          }).finally(() => {
            router.push({ name: '/vuser/login' })
          })
        } else {
          if (response.status.code !== '1002') {
            console.log('Error: ', response)
          }
          reject(response)
        }
      } else {
        resolve(response)
      }
    }).catch(error => {
      console.log(error)
      reject(error)
    })
  })
}

export function notificationWarn (err) {
  if (err.status && err.status.text) {
    err = err.status.text
  }

  if (err.message) {
    err = err.message
  }

  notification.warn({
    message: i18nRender('System Information'),
    description: err
  })
}

export function notificationError (err) {
  notification.error({
    message: i18nRender('app.error'),
    description: err
  })
}

export function notificationSuccess (message, description, duration) {
  notification['success']({
    message: message,
    description: description,
    duration: duration
  })
}

export function download (resp) {
  let blob = new Blob([resp.data])
  let url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', resp.fileName)
  document.body.appendChild(link)
  link.click()

  const dispose = function () {
    if (url) {
      url = window.URL.revokeObjectURL(url)
      blob = null
      link.remove()
    }
  }

  setTimeout(function () {
    dispose()
  }, 10000)
}

const imageTypes = {
  'folder': '/file_type/folder.png',
  'txt': '/file_type/txt.png',
  'pdf': '/file_type/pdf.png',
  'url': '/file_type/html.png',
  'intent': '/file_type/intent.png'
}

export function imageType (type) {
  const filetype = imageTypes[type]
  if (!filetype) {
    return imageTypes['txt']
  }

  return filetype
}

export function readLocalImage (file) {
  if (!/image\/\w+/.test(file.type)) {
    console.log(file.name + 'is not a image')
    return null
  }

  var reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function (e) {
    return reader.result
  }

  return null
}

export function getBase64 (img, callback, errorHandle) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.addEventListener('error', (error) => errorHandle(error))
  reader.readAsDataURL(img)
}

// usage: {{ file.size | prettyBytes }}
Vue.filter('prettyBytes', function (num) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    return ''
  }

  var exponent
  var unit
  var neg = num < 0
  var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  if (neg) {
    num = -num
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B'
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1)
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1
  unit = units[exponent]
  return (neg ? '-' : '') + num + ' ' + unit
})

export function formatPhone (phoneNumber) {
  var cleaned = ('' + phoneNumber).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return phoneNumber
}

Vue.filter('initialName', function (username) {
    const parts = username.split(/[ -]/)
    let initials = ''

    for (var i = 0; i < parts.length; i++) {
      initials += parts[i].charAt(0)
    }

    if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
      initials = initials.replace(/[a-z]+/g, '')
    }

    initials = initials.substr(0, 3).toUpperCase()

    return initials
})

export function extractColorByName (name) {
  var temp = []
  temp.push('#')
  for (let index = 0; index < name.length; index++) {
    temp.push(parseInt(name[index].charCodeAt(0), 10).toString(16))
  }
  return temp.slice(0, 5).join('').slice(0, 4)
}

export function initialName (username) {
  const parts = username.split(/[ -]/)
  let initials = ''

  for (var i = 0; i < parts.length; i++) {
    initials += parts[i].charAt(0)
  }

  if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
    initials = initials.replace(/[a-z]+/g, '')
  }

  initials = initials.substr(0, 3).toUpperCase()
  return initials
}

export function parseUsPhoneNumber (phone) {
  const phoneNumber = parsePhoneNumber(phone, 'US')
  if (phoneNumber) {
    return phoneNumber.formatNational()
  }
  return ''
}

export function phoneNumberTyping (phone) {
  return new AsYouType('US').input(phone)
}

export function validEmail (email) {
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return reg.test(email)
}

export function validatePhoneNumber (phone) {
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
  return re.test(phone)
}
