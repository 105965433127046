import router from '@/router'
import store from '@/store'
import storage from 'store'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
// import notification from 'ant-design-vue/es/notification'
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { i18nRender } from '@/locales'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const allowList = ['login', 'register', 'registerResult', 'registerResult2', 'resetPassword', 'resetPasswordResult', 'luckywheel'] // no redirect allowList
const loginRoutePath = '/vuser/login'
const oldLoginRoutePath = '/user/login'
const defaultRoutePath = '/dashboard/workplace'

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${i18nRender(to.meta.title)} - ${domTitle}`)
  if (allowList.includes(to.name)) {
    // 在免登录名单，直接进入
    next()
    return
  }

  /* has token */
  const token = storage.get(ACCESS_TOKEN)
  if (token) {
    if (store.state.vaiStore.user.roles.length !== 0) {
      if (to.path === loginRoutePath) {
        next({ path: defaultRoutePath })
        NProgress.done()
      } if (to.path === oldLoginRoutePath) {
        next({ path: defaultRoutePath })
        NProgress.done()
      } else {
        next()
      }
      return
    }

    // request login userInfo
    store.dispatch('vaiStore/user/GetInfo').then(res => {
        // 请求带有 redirect 重定向时，登录自动重定向到该地址
        const redirect = decodeURIComponent(from.query.redirect || to.path)
        if (to.path === redirect) {
          // set the replace: true so the navigation will not leave a history record
          // next({ ...to, replace: true })
          next({ ...to, replace: true })
        } else {
          // 跳转到目的路由
          next({ path: redirect })
        }
      }).catch((error) => {
        console.log(error)
        // notification.error({
        //  message: i18nRender('app.error'),
        //  description: i18nRender('user.getinfo.error')
        // })
        // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
        store.dispatch('vaiStore/user/Logout').then(() => {
          next({ path: loginRoutePath })
        }).catch(() => {
        }).finally(() => {
          storage.remove(ACCESS_TOKEN)
          next({ path: loginRoutePath })
        })
      })
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({ path: loginRoutePath, query: { redirect: to.fullPath } })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
