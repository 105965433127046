// eslint-disable-next-line
import { UserLayout, BasicLayout, VaiBasicLayout, LocationLayout, BlankLayout } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'

const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: 'menu.home' },
    redirect: '/promotion/dashboard',
    children: [
      // dashboard
      {
        path: '/promotion/dashboard',
        name: 'dashboard',
        component: () => import('@/vai/views/promotion/PromotionList'),
        meta: { title: 'Promotions', keepAlive: false, icon: 'bank', permission: ['dashboard'] }
      },
      {
        path: '/promotion/index/:promotion_id',
        name: 'promotion',
        component: () => import('@/vai/views/promotion/PromotionIndex'),
        hidden: true,
        meta: { title: 'Subscription', keepAlive: true, icon: bxAnaalyse, permission: ['dashboard'] }
      },
      {
        path: '/promotion/subscription',
        name: 'subscription',
        component: () => import('@/vai/views/promotion/Subscriptions'),
        meta: { title: 'Subscription', keepAlive: true, icon: 'dollar', permission: ['dashboard'] }
      },
      {
        path: '/promotion/setting',
        name: 'setting',
        component: () => import('@/vai/views/promotion/SettingPersonalInfo'),
        meta: { title: 'Settings', keepAlive: true, icon: 'setting', permission: ['dashboard'] }
      },
      {
        path: '/promotion/logout',
        name: 'logout',
        component: () => import('@/vai/views/promotion/Logout'),
        meta: { title: 'Logout', keepAlive: true, icon: 'logout', permission: ['dashboard'] }
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const userRouterMap = [
  {
    path: '/vuser',
    component: UserLayout,
    redirect: '/vuser/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/Register')
      },
      {
        path: '/vuser/register-result',
        name: 'registerResult2',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/RegisterResult')
      },
      {
        path: 'reset-password',
        name: 'resetPassword',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/ResetPassword')
      },
      {
        path: 'reset-password-result',
        name: 'resetPasswordResult',
        component: () => import(/* webpackChunkName: "user" */ '@/vai/views/user/ResetPasswordResult')
      }
    ]
  }, {
    path: '/luckywheel/:promotion_id',
    name: 'luckywheel',
    component: () => import('@/vai/luckywheel/App'),
    hidden: true
  }

]

export const vaiRouterMap = [
  {
    path: '/vai',
    component: VaiBasicLayout,
    meta: { title: 'Home' },
    redirect: '/vai/resetpassword',
    hidden: true,
    children: [
      {
        path: 'resetpassword',
        name: 'resetpassword',
        component: () => import('@/vai/views/user/ResetPassword')
      }
    ]
  }
]
